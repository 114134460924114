'use client'

import Image from 'next/image'
import Button from 'components/global/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { useState, useEffect } from 'react'
import { useSessionStorage } from 'utils/hooks'
import { event } from 'utils/gtm'

import Fondo from './mosca-etiquetas.png'
import FondoDana from './mosca-dana.png'

const MoscaDana = ({ section, tipologia }) => {
    const [displayMoscaDana, setDisplayMoscaDana] = useSessionStorage('mosca-dana', true)
    const [display, setDisplay] = useState(displayMoscaDana)
    const [version, setVersion] = useState('etiquetas')

    useEffect(() => {
        ;(async () => {
            const result = fetch('https://global-trust.eu/leaseplan/geo.php')
            const data = await (await result).json()
            console.log('data', data?.subdivisions)
            // data.subdivisions?.[0]?.names?.['es'] === 'Madrid'
            // data.subdivisions?.[0]?.iso_code === 'VC' || data.subdivisions?.[1]?.iso_code === 'VC'
            // let isTarragona = data?.subdivisions.find((sub) => sub.iso_code === 'T')
            // console.log('isTarragona', tarragona)
            // NL IE
            // if (data?.country?.iso_code === 'AD') {
            if (data.subdivisions?.[0]?.iso_code === 'VC' || data.subdivisions?.[1]?.iso_code === 'VC') {
                setVersion('dana')
            }
        })()

        return () => {
            // this now gets called when the component unmounts
        }
    }, [])

    if (section !== 'listado' && section !== 'home' && section !== 'coches') return <></>

    if (tipologia !== 'empresas' && tipologia !== 'autonomos' && tipologia !== 'particulares') {
        return <></>
    }

    const handleClick = () => {
        setDisplay(false)
        setDisplayMoscaDana(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - restricciones - esconder' })
    }

    const handleClick2 = () => {
        setDisplay(false)
        setDisplayMoscaDana(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - dana - esconder' })
    }

    const handleClickButton = (value) => {
        setDisplay(false)
        setDisplayMoscaDana(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - restricciones - ' + value })
    }

    const handleClickButton2 = (value) => {
        setDisplay(false)
        setDisplayMoscaDana(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - dana - ' + value })
    }

    const link = '/renting/?bajadaPrecio=true&entregaRapida=true&source=restricciones-interes'
    const link2 = '/contacto/?escribenos=true&utm_source=dana'

    return (
        <>
            {display && version === 'etiquetas' && (
                <div
                    className="invisible fixed bottom-[10%] right-0 grid h-[323px] w-[612px] grid-cols-2 xl:visible"
                    id="mosca-restricciones"
                >
                    <Image src={Fondo} alt="" fill objectFit="cover" />

                    <div className="absolute right-4 top-4 z-50 cursor-pointer">
                        <FontAwesomeIcon icon={faCircleXmark} className="text-2xl text-white" onClick={handleClick} />
                    </div>
                    <div className="absolute left-8 top-12 max-w-[16rem] space-y-4 text-white">
                        <div className="text-xl leading-none">
                            Dile adiós a las restricciones y circula sin preocupaciones.
                        </div>
                        <div className="text-sm">
                            A partir del 1 de enero de 2024, si no tienes etiqueta medioambiental, no podrás circular en
                            ciudades con Zonas de Bajas Emisiones (ZBE).
                        </div>
                        <div className="pt-8 text-center">
                            <Button
                                href={link}
                                onClick={() => handleClickButton('me interesa')}
                                variant="default"
                                size="xl"
                                className="px-8"
                            >
                                VER OFERTAS
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {display && version === 'dana' && (
                <div
                    className="invisible fixed bottom-[10%] right-0 grid h-[297px] w-[612px] grid-cols-2 xl:visible"
                    id="mosca-restricciones"
                >
                    <Image src={FondoDana} alt="" fill objectFit="cover" />

                    <div className="absolute right-4 top-4 z-50 cursor-pointer">
                        <FontAwesomeIcon icon={faCircleXmark} className="text-2xl text-white" onClick={handleClick2} />
                    </div>
                    <div className="absolute left-8 top-12 max-w-[16rem] space-y-4 text-white">
                        <div className="pt-[180px] text-center">
                            <Button
                                href={link2}
                                onClick={() => handleClickButton2('me interesa')}
                                variant="default"
                                size="xl"
                                className="border-[#5BC9CE] px-8 text-[#5BC9CE]"
                            >
                                CONTACTAR
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default MoscaDana
